<template>
    <div class="contaners_share">
        <img style="width:100%;height:100%" src="../../../assets/backgroundicon.png" />
        <div @click="mineMessage" class="messageTitle13">
            <img class="messageIcon" src="../../../assets/phone2.png" />
            <div>联系客服</div>
        </div>
        <div class="content_share">
            <div style="display:flex" class="share_text2">
                <img src="../../../assets/errorIcons.png" />
                <div>温馨提示:以下信息将作为合同信息,请根据实际填写。</div>
            </div>
            <div class="formSubmit_bottom">
                <div class="formSubmit">

                    <div class="formList">
                        <div class="offine_title" style="letter-spacing: 10px;">姓名:</div>
                        <input v-model="dataForm.username.value" />
                    </div>
                    <div class="formList">
                        <div class="offine_title">身份证号码:</div>
                        <input v-model="dataForm.Idcard.value" />
                    </div>
                    <div class="formList">
                        <div class="offine_title">联系方式:</div>
                        <input v-model="dataForm.phone.value" />
                    </div>
                    <!--<div class="formList">
                        <div class="offine_title">希望持股:</div>
                        <input v-model="dataForm.hopStake.value" />
                    </div> -->
                    <div style="display: flex">
                        <div class="offine_title">申请人现居地址:</div>
                        <textarea class="shipping1" v-model="dataForm.userAddress.value">

                    </textarea>
                    </div>
                </div>
                <!--<div style="color:#fff" v-if="queryList.id" class="offine_text">*经系统检测,您的补金为:{{tableData.info_state==3?userFrom.member.available_assets:(Number(userFrom.member.available_assets)+Number(settingDate.audit.shares_assets))}},可持有</div>
                <div style="color:#fff" v-else class="offine_text">*经系统检测,您的补金为:{{userFrom.member.available_assets}},可持有</div>-->

              <!--  <div v-if="!settingDate.shareholder_activity">
                    <div v-if="!queryList.id">
                        <div style="color:#fff" v-if="queryList.select==1" class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.config.shares_cardinal_1)}}</div>
                        <div style="color:#fff" v-else class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.config.shares_cardinal_2)}}</div>
                    </div>
                    <div style="color:#fff" v-else class="offine_text">
                        <div style="color:#fff" v-if="tableData.apply_type==1" class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                        <div style="color:#fff" v-else class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="!queryList.id">
                        <div style="color:#fff" v-if="queryList.select==1" class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.shareholder_activity.shares_cardinal_1)}}</div>
                        <div style="color:#fff" v-else class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.shareholder_activity.shares_cardinal_2)}}</div>
                    </div>
                    <div style="color:#fff" v-else class="offine_text">
                        <div style="color:#fff" v-if="tableData.apply_type==1" class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                        <div style="color:#fff" v-else class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                    </div>
                </div>-->
                <div class="address">收货详细地址:</div>
                <textarea class="shipping" v-model="dataForm.address.value"></textarea>
                <div style="display:flex">
                    <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box"></div>
                    <div v-else class="select_box" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
                    <div class="ruleBtn" @click="ruleBrn">我已熟知与查看《工会股东协议》</div>
                </div>
                <div class="office_submit" @click="submitBtn">
                    下一步
                </div>
            </div>

        </div>

        <!-- 签字弹窗 -->
        <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
            <div class="signtureShow_box">
                <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" :imgToken="imgToken" @cloneImg="cloneImg" @cloneBtn="cloneBtn" @imgUblod="imgUblod"></catSignature>
            </div>
        </div>

        <!-- 提示框 -->
        <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>
<script>
import * as apiCommon from "@/api/common";
// import wx from "weixin-js-sdk";
import Cookies from "js-cookie";
import catSignature from "../../../components/catSignature.vue";
import {
  shareholderApply_api,
  getuser_api,
  getSetting,
  detailShareholderApply_api
} from "@/api/common";
import toast from "@/components/toast";
export default {
  data() {
    return {
      dataForm: {
        username: {
          value: "",
          title: "姓名",
          text: "请输入姓名",
          color: "#DEB77A"
        },
        Idcard: {
          value: "",
          title: "身份证号",
          text: "请输入身份证号",
          color: "#DEB77A"
        },
        phone: {
          value: "",
          title: "联系方式",
          text: "请输入联系方式",
          color: "#DEB77A"
        },
        // hopStake: {
        //   value: "",
        //   title: "希望持股",
        //   text: "请输入希望持股",
        //   color: "#DEB77A"
        // },
        nameImg: {
          value: "",
          title: "签名",
          text: "请签名",
          color: "#DEB77A"
        },
        address: {
          value: "",
          title: "收货详细地址",
          text: "请输入收货详细地址",
          color: "#DEB77A"
        },
        userAddress: {
          value: "",
          title: "申请人现居地址",
          text: "请输入申请人现居地址",
          color: "#DEB77A"
        }
      },
      checked: 1,
      iconShow: false,
      toastShow: false,
      imgToken: "",
      imgWidth: 645,
      signtureShow: false,
      img: "",
      queryList: "",
      userFrom: {
        member: {}
      },
      configData: {
        title: ""
      },
      // 系統配置
      settingDate: {
        config: {},
        audit: {}
      },
      tableData: {},
      submitFaly:true
    };
  },
  components: {
    catSignature: catSignature,
    toast: toast
  },
  created() {
    this.queryList = this.$route.query;
    this.getToken();
    if (this.queryList.id) {
      this.getDetails(this.queryList.id);
    }
    // 获取用户信息
    this.getUser();
    // 获取系统配置
    this.getConfig();
    if (Cookies.get("dataForm")) {
      this.dataForm = JSON.parse(Cookies.get("dataForm"));
    }
  },
  methods: {
    getConfig() {
      getSetting().then(res => {
        console.log(res.status);
        if (res.code == 0) {
          this.settingDate = res.data;
          this.$forceUpdate();
        }
      });
    },
    cloneBtn() {
      this.signtureShow = false;
    },
    ruleBrn() {
      Cookies.set("dataForm", JSON.stringify(this.dataForm));
      this.$router.push("../rule");
    },
    getUser() {
      getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;

         
        }
      });
    },
    async getDetails(id) {
      let res = await apiCommon.getShareDetail_api(id);
      if (res.code == 0) {
        console.log(res.data);
        this.tableData = res.data;
        this.queryList.select = this.tableData.apply_type;
        this.dataForm.username.value = res.data.audit_info.name;
        this.dataForm.Idcard.value = res.data.audit_info.Idcard;
        this.dataForm.phone.value = res.data.audit_info.phone;
        this.dataForm.userAddress.value = res.data.audit_info.userAddress;
        this.dataForm.address.value = res.data.audit_info.address;
        // this.dataForm.hopStake.value = JSON.stringify(res.data.shares_amount);
      }
    },
    imgUblod(img) {
      this.img = img;
      this.dataForm.nameImg.value = img;
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
      this.iconShow = true;
      this.signtureShow = false;
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    mineMessage() {
      console.log(123);
      // wx.closeWindow();
      this.$router.push("../../lador/service");
    },
    checkoutBtn(index) {
      if (index == 1) {
        this.signtureShow = true;
      } else {
        this.iconShow = false;
      }
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    submitBtn() {
      if(!this.submitFaly){
        this.toastShow = true;
        this.configData.title = "请勿重复提交";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        return false;
      }
      this.submitFaly = false
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          this.toastShow = true;
          this.configData.title = this.dataForm.text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        this.submitFaly = true;
        return false;
      }
      if (this.queryList.id) {
        this.detail();
      } else {
        this.save();
      }
    },
    cloneImg() {
      (this.dataForm.nameImg.value = ""), localStorage.removeItem("imgName");
    },
    save() {
      let userData = {
        name: this.dataForm.username.value,
        Idcard: this.dataForm.Idcard.value,
        phone: this.dataForm.phone.value,
        // wxUser:this.dataForm.wxUser.value,
        nameImg: this.dataForm.nameImg.value,
        address: this.dataForm.address.value,
        userAddress: this.dataForm.userAddress.value
      };
      let data = {
        apply_type: this.queryList.select,
        // shares_amount: this.dataForm.hopStake.value,
        shares_amount: 0,
        audit_type: 1,
        audit_info: userData,
        mobile: this.dataForm.phone.value
      };
      for (var j in this.dataForm) {
        if (!this.dataForm[j].value) {
          this.toastShow = true;
          this.configData.title = this.dataForm[j].text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          this.submitFaly = true;
          return false;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        this.submitFaly = true;
        return false;
      }
      // if (
      //   this.dataForm.hopStake.value < 0 ||
      //   JSON.stringify(this.dataForm.hopStake.value).indexOf(".") != -1
      // ) {
      //   this.toastShow = true;
      //   this.configData.title = "希望持股必须是正整数";
      //   setTimeout(() => {
      //     this.toastShow = false;
      //   }, 1000);
      //   this.submitFaly = true;
      //   return false;
      // }
      let configSetting = null;
      if (this.queryList.id) {
        if (this.queryList.select == 1) {
          configSetting =
            Math.floor(
              this.userFrom.member.available_assets /
                this.settingDate.config.shares_cardinal_1
            ) + Number(this.settingDate.audit.shares_amount);
        } else {
          configSetting =
            Math.floor(
              this.userFrom.member.available_assets /
                this.settingDate.config.shares_cardinal_2
            ) + Number(this.settingDate.audit.shares_amount);
        }
      } else {
        if (this.queryList.select == 1) {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_1
          );
        } else {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_2
          );
        }
      }
      console.log(configSetting, "configSetting");

      // // 判斷可兌換股份
      // if (this.dataForm.hopStake.value > configSetting) {
      //   this.toastShow = true;
      //   this.configData.title = "希望持股不能大于最大持股";
      //   setTimeout(() => {
      //     this.toastShow = false;
      //   }, 1000);
      //   this.submitFaly = true;
      //   return false;
      // }
      shareholderApply_api(data).then(res => {
        this.submitFaly = true;
        if (res.code == 0) {
          Cookies.remove("dataForm");
          this.$router.push(`./onlineDetails?audit_id=${res.data.audit_id}`);
        } else {
          this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      });
    },
    detail() {
      let userData = {
        name: this.dataForm.username.value,
        Idcard: this.dataForm.Idcard.value,
        phone: this.dataForm.phone.value,
        // wxUser:this.dataForm.wxUser.value,
        nameImg: this.dataForm.nameImg.value,
        address: this.dataForm.address.value,
        userAddress: this.dataForm.userAddress.value
      };
      let data = {
        // apply_type:this.queryList.select,
        // shares_amount: this.dataForm.hopStake.value,
        shares_amount: 0,
        // audit_type:1,
        audit_info: userData
        // mobile:this.dataForm.phone.value
      };
      for (var j in this.dataForm) {
        if (!this.dataForm[j].value) {
          this.toastShow = true;
          this.configData.title = this.dataForm[j].text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          this.submitFaly = true;
          return false;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        this.submitFaly = true;
        return false;
      }
      let configSetting = null;
      // if(this.queryList.select==1){
      //     configSetting = Math.floor(this.userFrom.member.available_assets/this.settingDate.config.shares_cardinal_1)
      // }else{
      //     configSetting = Math.floor(this.userFrom.member.available_assets/this.settingDate.config.shares_cardinal_2)
      // }
      if (this.queryList.id) {
        if (this.queryList.select == 1) {
          configSetting =
            Math.floor(
              this.userFrom.member.available_assets /
                this.settingDate.config.shares_cardinal_1
            ) + Number(this.settingDate.audit.shares_amount);
        } else {
          configSetting =
            Math.floor(
              this.userFrom.member.available_assets /
                this.settingDate.config.shares_cardinal_2
            ) + Number(this.settingDate.audit.shares_amount);
        }
      } else {
        if (this.queryList.select == 1) {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_1
          );
        } else {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_2
          );
        }
      }
      // 判斷可兌換股份
      console.log(configSetting)
      // if (this.dataForm.hopStake.value > configSetting) {
        // this.toastShow = true;
        // this.configData.title = "希望持股不能大于最大持股";
        // setTimeout(() => {
        //   this.toastShow = false;
        // }, 1000);
        // this.submitFaly = true;
        // return false;
      // }
      // if (
      //   this.dataForm.hopStake.value < 0 ||
      //   JSON.stringify(this.dataForm.hopStake.value).indexOf(".") != -1
      // ) {
        // this.toastShow = true;
        // this.configData.title = "希望持股必须是正整数";
        // setTimeout(() => {
        //   this.toastShow = false;
        // }, 1000);
        // this.submitFaly = true;
        // return false;
      // }
      detailShareholderApply_api(data, this.queryList.id).then(res => {
        this.submitFaly = true;
        if (res.code == 0) {
          this.$router.push(`./onlineDetails?audit_id=${this.queryList.id}`);
        } else {
          this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      });
    }
  }
};
</script>
<style scoped type="text/css">
.contaners_share {
  width: 100%;
  /* height:100vh; */
  position: relative;
  font-size: 30px;
  color: #deb77a;
}
.content_share {
  position: absolute;
  top: 0;
  left: 0px;
  margin: 0 70px 0;
  /* width:666px; */
}
.messageTitle13 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle13 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 3;
}
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.header_contract div {
  display: flex;
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
}
.contranct_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  opacity: 1;
  margin-top: 25px;
}
.contranct_box {
  width: 515px;
  height: 567px;
  margin: 291px auto 0;
  position: relative;
}
.contranct_box img {
  width: 100%;
  height: 100%;
}

.formSubmit {
  margin-top: 100px;
}
.formSubmit_bottom {
  margin: 30px 0px 0 30px;
}
input {
  background-color: transparent;
  border: 1px solid #deb77a;
  width: 348px;
  height: 54px;
  padding-left: 20px;
  box-sizing: border-box;
}
.formList {
  display: flex;
  line-height: 54px;
  margin-bottom: 40px;
}
.offine_title {
  width: 200px;
  letter-spacing: 4px;
  font-size: 30px;
}
.offine_text {
  font-size: 25px;
  margin-bottom: 20px;
}
.shipping {
  width: 519px;
  height: 161px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 40px;
}
.shipping1 {
  width: 345px;
  height: 120px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 40px;
  padding-left: 20px;
}
.address {
  margin: 48px auto 24px;
}

.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 100px 157px 0;
}
.office_submit img {
  width: 100%;
  height: 100%;
}
.content_share img {
  width: 25px;
  height: 25px;
}
.share_text2 {
  margin-top: 100px;
  font-size: 25px;
  color: #fff;
}
.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}
.office_submit {
  background-color: #deb77a;
  border-radius: 50px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  color: #fff;
}
</style>